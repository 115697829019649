// custom typefaces
import "typeface-playfair-display"
import "typeface-montserrat"

import "prismjs/themes/prism.css"

import "./src/global.css"

//** GOOGLE AUTOTRACK **//

import 'autotrack/lib/plugins/event-tracker';
import 'autotrack/lib/plugins/max-scroll-tracker';
import 'autotrack/lib/plugins/media-query-tracker';
import 'autotrack/lib/plugins/outbound-link-tracker';
import 'autotrack/lib/plugins/page-visibility-tracker';

window.ga('require', 'eventTracker');
window.ga('require', 'maxScrollTracker', {
    maxScrollMetricIndex: 1
});
window.ga('require', 'mediaQueryTracker');
window.ga('require', 'outboundLinkTracker', {
    events: ['click', 'auxclick', 'contextmenu']
});
window.ga('require', 'pageVisibilityTracker');

//** END GOOGLE AUTOTRACK **//

//** GOOGLE BUILTIN PLUGINS **//

window.ga('require', 'ec');
window.ga('require', 'linker');

//** END GOOGLE BUILTIN PLUGINS **//