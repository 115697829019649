module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-mdx/gatsby-browser.js'),
      options: {"plugins":[],"excerpt_separator":"<!-- excerpt -->","extensions":[".md",".mdx"],"gatsbyRemarkPlugins":[{"resolve":"gatsby-remark-images","options":{"maxWidth":630,"showCaptions":true,"linkImagesToOriginal":false,"tracedSVG":{"color":"#9ba8b3"}}},{"resolve":"gatsby-remark-responsive-iframe","options":{"wrapperStyle":"margin-bottom: 1.0725rem"}},"gatsby-remark-prismjs","gatsby-remark-copy-linked-files","gatsby-remark-smartypants",{"resolve":"gatsby-remark-external-links","options":{"target":"_blank","rel":null}}]},
    },{
      plugin: require('../node_modules/gatsby-plugin-image/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-gtag/gatsby-browser.js'),
      options: {"plugins":[],"trackingIds":["G-4R7SLQLNZ4"],"gtagConfig":{"optimize_id":"GTM-TT7HHQR","anonymize_ip":true,"cookie_expires":0},"pluginConfig":{"head":false,"anonymize":true,"respectDNT":true,"forceSSL":true}},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"The Scubaneer","short_name":"theScubaneer","start_url":"/","background_color":"#ffffff","theme_color":"#466f94","display":"minimal-ui","icon":"content/assets/s-icon.png","cache_busting_mode":"name","legacy":true,"theme_color_in_head":true,"crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"64ee675c28cedfa3a4694e04b8b9da5f"},
    },{
      plugin: require('../node_modules/gatsby-plugin-typography/gatsby-browser.js'),
      options: {"plugins":[],"pathToConfigModule":"src/utils/typography"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
